import { render, staticRenderFns } from "./LearnIndex.vue?vue&type=template&id=a5f37acc&scoped=true&slot-scope=%7B%20loading%20%7D"
import script from "./LearnIndex.vue?vue&type=script&lang=js"
export * from "./LearnIndex.vue?vue&type=script&lang=js"
import style0 from "./LearnIndex.vue?vue&type=style&index=0&id=a5f37acc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5f37acc",
  null
  
)

export default component.exports
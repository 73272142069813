import { render, staticRenderFns } from "./ToggleHeaderTabs.vue?vue&type=template&id=4161c774&scoped=true"
import script from "./ToggleHeaderTabs.vue?vue&type=script&lang=js"
export * from "./ToggleHeaderTabs.vue?vue&type=script&lang=js"
import style0 from "./ToggleHeaderTabs.vue?vue&type=style&index=0&id=4161c774&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4161c774",
  null
  
)

export default component.exports